import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Promotion = ({ location }) => (
  <Layout>
    <SEO title="Aktualne Promocje" />
    <div className="container">
      <div className="main">
        <ol>
          <li><Link to="/">Strona główna</Link> / <Link to="/promotion">Aktualne Promocje</Link></li>
        </ol>
        <h1>Aktualne Promocje:</h1>
        <hr></hr>
        <p>Aktualnie brak promocji. <a href="https://www.fototransfer.pl/#footer" className="colored-href">Zapisz się do newslettera</a>, by być na bieżąco z nowościami i promocjami.</p>
      </div>
    </div>
    {/* <Link to="/">Go back to the homepage</Link> */}
  </Layout>
)

export default Promotion
